<template>
    <div class="bg-gray-100 py-12 flex items-center justify-center pt-32 pb-40">
      <div class="max-w-3xl text-center px-6">
        <h2 class="text-3xl font-extrabold text-gray-900">
          Torne-se um parceiro
        </h2>
        <p class="mt-4 text-gray-600">
          Estamos comprometidos em ajudar nossos parceiros fornecendo canais para
          acelerar o crescimento dos negócios, e atender às necessidades dos
          clientes com soluções inovadoras e flexíveis.
          Você pode contar com nosso suporte 24 horas por dia, 7 dias por semana, 365 dias por ano, para ajudar a resolver e publicar seus aplicativos.
        </p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "ThirdPage",
  };
  </script>
  
  <style scoped>
  body {
    margin: 0;
    font-family: Arial, sans-serif;
  }
  </style>
  