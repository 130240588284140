<template>
    <div class="relative bg-gray-100 flex items-start justify-center pt-20 pb-10 shadow-2xl shadow-gray-300">
      <!-- Container Principal -->
      <div class="max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8 items-start px-4 lg:px-6">
        <!-- Conteúdo de Texto -->
        <div>
          <span class="text-yellow-600 bg-yellow-100 px-3 py-1 rounded-md text-sm font-semibold">
            LANÇAMENTO
          </span>
          <h1 class="mt-4 text-4xl font-extrabold text-gray-900 leading-tight">
            iContainer Panel
          </h1>
          <h2 class="mt-2 text-xl text-gray-800 font-semibold">
            Publique seus aplicativos em VPS com simplicidade
          </h2>
          <p class="mt-4 text-gray-600">
            Utilize nosso sistema para manipular containers Docker com toda a liberdade que existe para um administrador, contando com a simplicidade de modelos pré-criados para tornar mais rápido sua publicação na Internet.

          </p>
          <a href="https://integrator.com.br/icp.html" class="text-blue-600 font-medium mt-2 inline-block">Saiba mais</a>
  
          <!-- Botões -->
          <div class="mt-6 flex space-x-4">
            <a
              href="https://integrator.com.br/plano-vps-combo-icp.html"
              class="px-6 py-3 bg-blue-600 text-white font-semibold rounded-md shadow hover:bg-blue-700"
            >
              Contrate agora
            </a>
            <a
              href="https://integrator.com.br/assineja/vpsicp.html?checkout=vps-icp-1"
              class="px-6 py-3 border border-black text-black font-semibold rounded-md hover:bg-gray-100"
            >
              Apenas R$ 59,90*/mês
            </a>
          </div>
          <p class="mt-4 text-sm text-gray-500">
            * <a href="https://integrator.com.br/plano-vps-combo-icp.html" class="text-blue-600 font-medium">Saiba mais sobre preços e suporte aqui</a>
          </p>
        </div>
  
        <!-- Imagem -->
        <div class="relative">
          <img src="../assets/home-destaque.jpeg" alt="Mac com Windows" class="rounded-lg shadow-lg">
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "FirstPage",
  };
  </script>
  
  <style scoped>
  body {
    margin: 0;
    font-family: Arial, sans-serif;
  }
  </style>
  