<template>
    <nav class="bg-blue-950 text-white p-2">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex justify-between items-center h-16">
          <!-- Logo -->
          <div class="flex-shrink-0 flex items-center">
            <img src="../assets/logo-icp.png" alt="Logo" class="h-10 w-15" />
            
          </div>
        </div>
      </div>
    </nav>
  </template>
  
  <script>
  export default {
    name: "NavbarPart",
  };
  </script>
 