<template>
  <div>
    <Navbar/>
    <FirstPage />
    <SecondPage/>
    <ThirdPage/>
  </div>
</template>

<script>
import FirstPage from './components/FirstPage.vue';
import Navbar from './components/NavPart.vue';
import SecondPage from './components/SecondPage.vue'
import ThirdPage from './components/ThirdPage.vue';

export default {
  name: 'App',
  components: {
    FirstPage,
    Navbar,
    SecondPage,
    ThirdPage
  },
};
</script>
