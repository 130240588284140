<template>
    <div class="bg-blue-100 py-12 pt-20">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <!-- Título -->
        <h2 class="text-3xl font-extrabold text-center text-gray-900 mb-16">
          A solução para publicar seus aplicativos
        </h2>
  
        <!-- Grid de Produtos -->
        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
          <!-- Card 1 -->
          <div class="bg-white rounded-lg shadow-2xl shadow-blue-800 p-6 text-center">
            <img
              src="../assets/home-destaques/app-store.jpg"
              alt="Parallels RAS"
              class="w-full h-40 object-cover rounded-lg mb-4"
            />
            <h3 class="text-lg font-bold text-gray-800">App Store</h3>
            <p class="text-sm text-gray-600 mt-2">
              Aplicativos e tecnologias populares em Containers para uso imediato, pré-configurados. Com a App Store, você seleciona e instalar sem precisar entender de Container, Linux ou de como configurar o aplicativo.
             
            </p>
            <a
              href="https://wiki.icontainer.run/pt-br/app-store"
              class="mt-4 inline-block px-6 py-2 border border-gray-800 text-gray-800 font-medium rounded-md hover:bg-gray-100"
            >
              Saiba mais
            </a>
          </div>
  
          <!-- Card 2 -->
          <div class="bg-white rounded-lg shadow-md p-6 shadow-2xl shadow-blue-800  text-center">
            <img
              src="../assets/home-destaques/dominio-ssl.jpg"
              alt="Parallels Secure Workspace"
              class="w-full h-40 object-cover rounded-lg mb-4"
            />
            <h3 class="text-lg font-bold text-gray-800">
              Domínio e SSL
            </h3>
            <p class="text-sm text-gray-600 mt-2">
              Com poucos cliques você pode colocar domínios e configurar SSL apontando para aplicativos que instalou. 
              O painel permite colocar SSL gratuíto como o Let's Encrypt, ou até mesmo configurar certificados pagos.
            </p>
            <a
              href="https://wiki.icontainer.run/pt-br/ic-web"
              class="mt-4 inline-block px-6 py-2 border border-gray-800 text-gray-800 font-medium rounded-md hover:bg-gray-100"
            >
              Saiba mais
            </a>
          </div>
  
          <!-- Card 3 -->
          <div class="bg-white rounded-lg shadow-md p-6 shadow-2xl shadow-blue-800  text-center">
            <img
              src="../assets/home-destaques/aplicativos-standlone.jpg"
              alt="Parallels DaaS"
              class="w-full h-40 object-cover rounded-lg mb-4"
            />
            <h3 class="text-lg font-bold text-gray-800">Aplicativos Standalone</h3>
            <p class="text-sm text-gray-600 mt-2">
              Use um assistente de publicação para trabalhar com tecnologias PHP, Java, .Net, Ruby, Python e Go. 
              Publique aplicativos como WordPress, Laravel, Spring Boot, Quarkus, ASP.NET, Ruby on Rails, Django, Flask, Gin e etc.
            </p>
            <a
              href="https://wiki.icontainer.run/standalone"
              class="mt-4 inline-block px-6 py-2 border border-gray-800 text-gray-800 font-medium rounded-md hover:bg-gray-100"
            >
              Saiba mais
            </a>
          </div>
  
          <!-- Card 4 -->
          <div class="bg-white rounded-lg shadow-md  shadow-2xl shadow-blue-800  p-6 text-center">
            <img
              src="../assets/home-destaques/banco-dados.jpg"
              alt="Parallels Browser Isolation"
              class="w-full h-40 object-cover rounded-lg mb-4"
            />
            <h3 class="text-lg font-bold text-gray-800">
              Banco de dados
            </h3>
            <p class="text-sm text-gray-600 mt-2">
              Gerencie bancos de dados como MySQL, PostgreSQL e etc, usando nossos assistentes. 
              Instale várias versões de um servidor de banco de dados, configure usuários, dê permissões e tenha acesso acesso remoto sem fazer qualquer esforço.
            </p>
            <a
              href="https://wiki.icontainer.run/pt-br/banco-de-dados"
              class="mt-4 inline-block px-6 py-2 border border-gray-800 text-gray-800 font-medium rounded-md hover:bg-gray-100"
            >
              Saiba mais
            </a>
          </div>
        </div>
  
        <!-- Link Final -->
        <div class="text-center mt-20">
          <a
            href="https://integrator.com.br/plano-vps-combo-icp.html"
            class="text-blue-800 font-medium hover:underline inline-flex items-center bg-blue-200 p-3 rounded-xl border-2 border-white"
          >
            Ver todas as características do produto
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              class="w-5 h-5 ml-1"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M13 5l7 7m0 0l-7 7m7-7H6"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "SecondPage",
  };
  </script>
  
  <style scoped>
  body {
    margin: 0;
    font-family: Arial, sans-serif;
  }
  </style>
  